import { Button, Form, Input} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import axios from "axios";
import { errorHandler } from "../utils/common";
import { useForm } from "antd/es/form/Form";
import { useAuth } from "../context/AuthContext";
import { toast } from "react-toastify";

const rules = [{ required: true, message: "This is a required field" }];

function Profile() {
  const navigate = useNavigate();
  const { user, setUser }: any = useAuth();
  const [form] = useForm();
  const [loading, setLoading] = useState<any>(false);

  useEffect(() => {
    form.setFieldsValue({
      ...user
    })
  }, [user]);

  const onSubmit = async (values: any) => {
    setLoading(true);
    await axios
      .post(`users/${user.iD}/update`, values)
      .then((res) => {
        setUser({
          ...user,
          values
        })
        navigate(-1);
        toast('Profile Updated', {type: 'success'})
      })
      .catch(errorHandler);
    setLoading(false);
  };

  const rules = [{ required: true, message: "This is a required field" }];

  return (
    <>
      <Navbar />
      <div className="max-w-screen-lg mx-auto px-4 py-4">
        <h1 className="text-2xl font-semibold text-center my-2">My Profile</h1>

        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item label={"Name"} rules={rules} name={'name'}>
            <Input maxLength={100} />
          </Form.Item>
          <Form.Item label={"Email"} rules={rules} name={'email'}>
            <Input type="email" maxLength={100} />
          </Form.Item>
          <Button
            className="mt-4"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form>
      </div>
    </>
  );
}

export default Profile;
