import { Button, Form, Input } from "antd";
import axios from "axios";
import React, { useContext, useState } from "react";
import { errorHandler } from "../utils/common";
import { AuthProvider, useAuth } from "../context/AuthContext";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

function Login() {
  const {login}: any = useAuth();  
  const  navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const onSubmit = async (values: any) => {
    console.log(values);

    setLoading(true);
    await axios
      .post(`auth/exists`, values)
      .then((res) => {
        const {verified, profile, profileUpdated} = res.data.data;
        // if(verified){
          const {token} = res.data.data.user;
          login(token, res.data.data.user);
          navigate('history')
        // }else{
        //   toast.error('Not Verified User')
        // }
      })
      .catch(errorHandler);
    setLoading(false);
  };

  return (
    <div
      className="w-full flex flex-col items-center gap-10 mt-36"
      style={{ height: "100vh" }}
    >
      <img
        src={require("../images/logo.png")}
        alt="Logo"
        style={{ width: 200, height: 200 }}
      />
      <div className="bg-white p-5 sm:w-1/2 lg:w-1/3 xl:w-1/4 w-80 rounded-xl border-2">
        <h1 className="text-xl text-center font-semibold">
          Enter your phone number to login
        </h1>

        <Form layout="vertical" onFinish={onSubmit}>
          <Form.Item
            className="mt-5"
            label={""}
            name={"phone"}
            rules={[
              { required: true, message: "This is a required field" },
              {
                pattern: /^03/,
                message: 'The input must start with 03!',
              },
              {
                pattern: /^\d{11}$/,
                message: "The input must be exactly 11 digits!",
              },
            ]}
          >
            <Input
              placeholder="03*********"
              className="w-full"
              maxLength={11}
              onChange={(e) => {
                const { value } = e.target;
                if (!/^\d*$/.test(value)) {
                  e.target.value = value.replace(/\D/g, '');
                }
              }}
            />
          </Form.Item>

          <div className="text-center mt-8">
            <Button loading={loading} htmlType={"submit"} type="primary">
              LOG IN
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default Login;
