import { Button, Col, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import axios from "axios";
import { errorHandler } from "../utils/common";
import { useForm } from "antd/es/form/Form";
import { MapComponent } from "../components/MapComponent";
import { useAuth } from "../context/AuthContext";

const rules = [{ required: true, message: "This is a required field" }];

function AddressForm() {
  const navigate = useNavigate();
  const { user }: any = useAuth();
  const [form] = useForm();
  const [loading, setLoading] = useState<any>(false);
  const [position, setPosition] = useState<any>();

  const [hydrant, setHydrant] = useState<any>(null);
  const [areas, setAreas] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [sectors, setSectors] = useState([]);
  useEffect(() => {
    user && getArea();
  }, [user]);

  const getArea = async () => {
    if (areas.length) return;
    setLoading(true);
    await axios
      .get("users/data")
      .then((res) => {
        const { areas } = res.data.data;
        setAreas(areas);
      })
      .catch(errorHandler);
    setLoading(false);

  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    await axios
      .post(`users/${user.iD}/addresses`, {
        label: values.label,
        address: values.address,
        latitude: position.lat,
        longitude: position.lng,
        area: values.area_id,
        block: values.block_id,
        sector: values.sector_id,
        hydrant: hydrant?.iD,
      })
      .then((res) => {
        navigate("/addresses");
      })
      .catch(errorHandler);
    setLoading(false);
  };

  return (
    <>
      <Navbar />
      <div className="max-w-screen-lg mx-auto px-4 py-4">
        <h1 className="text-2xl font-semibold text-center my-2">
          Address Form
        </h1>

        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                label={"Label"}
                name={"label"}
                initialValue={"Home"}
                rules={rules}
              >
                <Select
                  loading={loading}
                  options={[
                    {
                      label: "Home",
                      value: "Home",
                    },
                    {
                      label: "Work",
                      value: "Work",
                    },
                    {
                      label: "Other",
                      value: "Other",
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={"Area"} name={"area_id"} rules={rules}>
                <Select
                  loading={loading}
                  showSearch={true}
                  optionFilterProp="label"
                  options={areas.map((area: any) => ({
                    label: area.title,
                    value: area.iD,
                  }))}
                  onSelect={(val) => {
                    const area: any = areas.find(
                      (area: any) => area.iD === val
                    );
                    setBlocks(area.block);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={"Block"} name={"block_id"} rules={rules}>
                <Select
                  loading={loading}
                  showSearch={true}
                  optionFilterProp="label"
                  options={blocks.map((block: any) => ({
                    label: block.title,
                    value: block.iD,
                  }))}
                  onSelect={(val) => {
                    const block: any = blocks.find(
                      (area: any) => area.iD === val
                    );
                    setSectors(block.sector);
                    setHydrant(block.hydrant);
                    form.setFieldValue("hydrant", block.hydrant.title);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={"Sector"} name={"sector_id"} rules={rules}>
                <Select
                  loading={loading}
                  showSearch={true}
                  optionFilterProp="label"
                  options={sectors.map((block: any) => ({
                    label: block.title,
                    value: block.iD,
                  }))}
                  onSelect={(val) => {
                    const sector: any = sectors.find(
                      (area: any) => area.iD === val
                    );
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item label={"Hydrant"} name={"hydrant"}>
                <Input disabled={true} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label={"Address"} name={"address"} rules={rules}>
                <Input />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item label={"Latitude"}>
                <Input disabled={true} value={position?.lat} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label={"Longitude"}>
                <Input disabled={true} value={position?.lng} />
              </Form.Item>
            </Col>
          </Row>

          <MapComponent onChange={setPosition} />

          <Button
            className="mt-4"
            type="primary"
            htmlType="submit"
            loading={loading}
          >
            Save
          </Button>
        </Form>
      </div>
    </>
  );
}

export default AddressForm;
