import {
  Button,
  Checkbox,
  Collapse,
  Empty,
  Form,
  Input,
  Radio,
  Select,
  Typography,
} from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import axios from "axios";
import { errorHandler } from "../utils/common";
import { useAuth } from "../context/AuthContext";
import { useForm } from "antd/es/form/Form";
import { toast } from "react-toastify";

function RequestTanker() {
  const navigate = useNavigate();
  const { user, addresses }: any = useAuth();
  const [form] = useForm();
  const [loading, setLoading] = useState(false);
  const [gallons, setGallons] = useState([]);
  const [estimate, setEstimate] = useState<any>({});

  useEffect(() => {
    if(addresses.length === 0){
      toast("Address required", { type: "warning" });
      navigate("/addresses");
    }
  }, [user]);

  const addressSelect = async (sector_id: any) => {
    setLoading(true);
    await axios
      .get(`sectors/${sector_id}/gallons`)
      .then((res) => {
        setGallons(res.data.data);
      })
      .catch(errorHandler);
    setLoading(false);
  };

  const onGallonSelect = async () => {
    setLoading(true);
    await axios
      .post(`users/${user.iD}/calculate`, {
        ...form.getFieldsValue(),
      })
      .then((res) => {
        setEstimate(res.data.data);
      })
      .catch(errorHandler);
    setLoading(false);
  };

  const onSubmit = async (values: any) => {
    setLoading(true);
    await axios
      .post(`users/${user.iD}/orders`, {
        ...values
      })
      .then((res) => {
        toast.success('Order created successfully')
        navigate('/history');
      })
      .catch(errorHandler);
    setLoading(false);
    
  }

  const rules = [{ required: true, message: "This is a required field" }];

  return (
    <>
      <Navbar />
      <div className="max-w-screen-lg mx-auto px-4 py-4">
        <Form layout="vertical" form={form} onFinish={onSubmit}>
          <Form.Item
            label={"Tanker Service"}
            name={"service"}
            initialValue={"gps"}
          >
            <Select
              loading={loading}
              options={[
                {
                  label: "GPS",
                  value: "gps",
                },
              ]}
            />
          </Form.Item>
          <Form.Item label={"Address"} name={"address"} valuePropName="value" rules={rules}>
            <Radio.Group className="flex gap-2 flex-col">
              {addresses.length ? (
                addresses.map((i: any) => (
                  <Radio
                    value={i.iD}
                    className="border p-4 rounded-lg flex bg-white"
                    onChange={(val) => {
                      val.target.checked && addressSelect(i.sector.iD);
                    }}
                  >
                    <h2 className="font-semibold">{i.label}</h2>
                    <p>{i.address}</p>
                  </Radio>
                ))
              ) : (
                <Empty />
              )}
            </Radio.Group>
          </Form.Item>

          <Form.Item label={"Gallons"} name={"gallon"} rules={rules}>
            <Select
              onSelect={onGallonSelect}
              loading={loading}
              options={gallons.map((i: any) => ({
                label: i.title,
                value: i.iD,
              }))}
            />
          </Form.Item>

          <div className="flex flex-col lg:w-1/2 w-full border rounded-lg p-4 bg-white items-center gap-2">
            <h2 className="font-semibold text-2xl">Tanker Fare</h2>
            <div className="flex flex-row justify-between w-full">
              <p>Hydrant</p>
              <p>{estimate?.hydrant?.title || "N/A"}</p>
            </div>
            <div className="flex flex-row justify-between w-full">
              <p>Tanker Charges</p>
              <p>Rs. {estimate?.tanker_charges || "0.0"}</p>
            </div>
            <div className="flex flex-row justify-between w-full">
              <p>Distance Fare</p>
              <p>
                Rs.{" "}
                {estimate.charges_per_km
                  ? (estimate.charges_per_km * estimate.distance).toFixed(1)
                  : "0.0"}
              </p>
            </div>
            <div className="flex flex-row justify-between w-full">
              <p>Total (approx)</p>
              <p>Rs. {estimate.total || "0.0"}</p>
            </div>
          </div>
          <div className="mt-2 gap-1 flex">
            <ExclamationCircleOutlined />
            <p>All prices given are subject to change with distance</p>
          </div>

          <Button className="mt-4" type="primary" loading={loading} htmlType="submit">
            Reqeust Tanker
          </Button>
        </Form>
      </div>
    </>
  );
}

export default RequestTanker;

{
  /* <Checkbox
                value={i.iD}
                className="border p-4 rounded-lg flex bg-white"
                onChange={(val) => {
                  val.target.checked && addressSelect(i.sector.iD);
                }}
              >
                <h2 className="font-semibold">{i.label}</h2>
                <p>{i.address}</p>
              </Checkbox> */
}
