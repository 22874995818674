import Orders from "./pages/Orders";
import Login from "./pages/Login";
import {
  HashRouter,
  useNavigate,
  useRoutes,
} from "react-router-dom";
import Settings from "./pages/Settings";
import RequestTanker from "./pages/RequestTanker";
import Addresses from "./pages/Addresses";
import AddressForm from "./pages/AddressForm";
import { useAuth } from "./context/AuthContext";
import { useEffect } from "react";
import Profile from "./pages/Profile";

const AuthChecker = ({ children }: any) => {
  const { token }: any = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const storedToken = localStorage.getItem('token');
    (!token && !storedToken) && navigate("/");
  }, [token]);

  return children;
};

const AuthRouter = () => {
  let routes = useRoutes([
    { path: "/", element: <Login />, index: true },
    {
      path: "/history",
      element: (
        <AuthChecker>
          <Orders />
        </AuthChecker>
      ),
    },
    {
      path: "/request",
      element: (
        <AuthChecker>
          <RequestTanker />
        </AuthChecker>
      ),
    },
    {
      path: "/settings",
      element: (
        <AuthChecker>
          <Settings />
        </AuthChecker>
      ),
    },
    {
      path: "/addresses",
      element: (
        <AuthChecker>
          <Addresses />
        </AuthChecker>
      ),
    },
    {
      path: "/address",
      element: (
        <AuthChecker>
          <AddressForm />
        </AuthChecker>
      ),
    },
    {
      path: "/profile",
      element: (
        <AuthChecker>
          <Profile />
        </AuthChecker>
      ),
    },
  ]);
  return routes;
};

function App() {
  const { token }: any = useAuth();

  return (
    <HashRouter basename="/">
      <AuthRouter />
    </HashRouter>
  );
}

export default App;
