import {
  Button,
  Checkbox,
  Collapse,
  Form,
  Input,
  List,
  Popconfirm,
} from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useAuth } from "../context/AuthContext";

function Settings() {
  const { logout }: any = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  return (
    <>
      <Navbar />
      <div className="max-w-screen-lg mx-auto px-4 py-4">
        <List bordered className="bg-white">
          <List.Item className="cursor-pointer hover:bg-slate-100"
            onClick={() => navigate('/profile')}
          >
            Profile
          </List.Item>
          <List.Item
            className="cursor-pointer hover:bg-slate-100"
            onClick={() => navigate("/addresses")}
          >
            Addresses
          </List.Item>
          <List.Item className="cursor-pointer hover:bg-slate-100">
            Help
          </List.Item>
          <List.Item className="cursor-pointer hover:bg-slate-100">
            <Popconfirm
              title={"Are you sure you want to logout"}
              className="flex-1"
              onConfirm={logout}
            >
              Logout
            </Popconfirm>
          </List.Item>
        </List>
      </div>
    </>
  );
}

export default Settings;
