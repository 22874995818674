import { Button, Empty, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import { useAuth } from "../context/AuthContext";
import axios from "axios";
import { errorHandler } from "../utils/common";

function Addresses() {
  const navigate = useNavigate();
  const { user, addresses, setAddresses }: any = useAuth();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    user && getAddresses();
  }, [user]);

  const getAddresses = async () => {
    setLoading(true);
    await axios
      .get(`users/${user.iD}/addresses`)
      .then((res) => {
        setAddresses(res.data.data);
      })
      .catch(errorHandler);
    setLoading(false);
  };

  const onDelete = async (id: any) => {
    setLoading(true);
    await axios
      .delete(`users/${user.iD}/addresses/${id}`)
      .then((res) => {
        setAddresses((prev: any) => prev.filter((i: any) => i.iD != id));
      })
      .catch(errorHandler);

    setLoading(false);
  };

  return (
    <>
      <Navbar />
      <div className="max-w-screen-lg mx-auto px-4 py-4 flex flex-col gap-4">
        <h1 className="text-2xl font-semibold text-center my-2">
          My Addresses
        </h1>

        {loading ? (
          <Skeleton />
        ) : addresses?.length ? (
          addresses.map((address: any) => (
            <div key={address.iD}>
              <div className="bg-white border p-4 rounded-lg gap-5">
                <div className="flex">
                  <div className="flex-1">
                    <h2 className="font-semibold">{address.label}</h2>
                    <p className="flex-wrap">{address.address}</p>
                  </div>
                  <div className="flex flex-col items-end gap-2">
                    {address.status ? (
                      <div className="bg-green-100 border border-green-300 text-green-700 rounded text-sm px-2 py-1">
                        Active
                      </div>
                    ) : (
                      <div className="bg-red-100 border border-red-300 text-red-700 rounded text-sm px-2 py-1">
                        In Active
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <i>To update your address, please contact our call center (<a href="tel:+92 304 1112482">+92 304 1112482</a>) </i>
            </div>
          ))
        ) : (
          <Empty />
        )}

        {addresses?.length < 1 && (
          <Button
            type="primary"
            onClick={() => navigate("/address")}
            loading={loading}
          >
            + Add Address
          </Button>
        )}
      </div>
    </>
  );
}

export default Addresses;
