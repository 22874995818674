import axios from 'axios';
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext({});

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);
  const [addresses, setAddresses] = useState(null);

  useEffect(() => {
    // Load token and user from localStorage on mount
    const storedToken: any = localStorage.getItem('token');
    const storedUser = localStorage.getItem('user');
    axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`

    if (storedToken) {
      setToken(storedToken);
    };
    if (storedUser) setUser(JSON.parse(storedUser));
  }, []);

  const login = (newToken: any, newUser: any) => {
    setToken(newToken);
    setUser(newUser);
    axios.defaults.headers.common['Authorization'] = `Bearer ${newToken}`
    localStorage.setItem('token', newToken);
    localStorage.setItem('user', JSON.stringify(newUser));
  };

  const logout = () => {
    setToken(null);
    setUser(null);
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  };

  return (
    <AuthContext.Provider value={{ token, user, setUser, login, logout, addresses, setAddresses }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);