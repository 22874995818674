import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import axios from "axios";
import { Collapse, Empty, Skeleton } from "antd";
import { toast } from "react-toastify";
import { errorHandler } from "../utils/common";
import { useAuth } from "../context/AuthContext";

function Orders() {
  const navigate = useNavigate();
  const { user, setAddresses }: any = useAuth();
  const [loading, setLoading] = useState(false);
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    if (user) {
      axios.get(`users/${user.iD}/addresses`).then((res) => {
        setAddresses(res.data.data);
        if (res.data.data.length === 0) {
          toast("Address required", { type: "warning" });
          navigate("/addresses");
        }
      });

      user && getOrders();
    }
  }, [user]);

  const getOrders = async () => {
    setLoading(true);
    await axios
      .get(`users/${user.iD}/orders`)
      .then((res) => {
        const { records } = res.data.data;
        setOrders(records);
      })
      .catch(errorHandler);
    setLoading(false);
  };

  return (
    <>
      <Navbar />
      <div className="max-w-screen-lg mx-auto px-4 py-4">
        {loading ? (
          <Skeleton />
        ) : orders.length ? (
          <Collapse defaultActiveKey={["1"]}>
            {orders.map((order: any, index: number) => (
              <Collapse.Panel
                header={`Order No#: ${order.orderNo} (${order.createdAt})`}
                key={order.orderNo}
              >
                <div className="flex">
                  <div className="flex-1">
                    <p>
                      Order No: <b>{order.orderNo}</b>
                    </p>
                    <p>Address: {order.address}</p>
                    <p>Gallons: {order.gallon?.title}</p>
                    <p>Hydrant: {order.hydrant?.title}</p>
                    <p>Distance: {order.totalKms}</p>
                    <p>Total Amount: Rs. {order.totalAmount}</p>
                  </div>
                  <div className="">
                    <span className="border rounded p-2 bg-orange-100 border-orange-200 text-orange-700">
                      {order.state?.title}
                    </span>
                  </div>
                </div>
              </Collapse.Panel>
            ))}
          </Collapse>
        ) : (
          <Empty description="No record" />
        )}
      </div>
    </>
  );
}

export default Orders;
