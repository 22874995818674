import React, { useEffect, useState } from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
  useMapEvent,
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";

// Fix for marker icons not showing correctly
// delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon-2x.png",
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

const DraggableMarker = ({ position, setPosition }: any) => {
  const map: any = useMapEvents({
    dragend() {
      const marker = map.dragging._marker;
      console.log("map.dragging", map.dragging);

      //   setPosition(marker.getLatLng());
    },
  });

  return (
    <Marker
      draggable={true}
      position={position}
      eventHandlers={{
        dragend: (event) => {
          console.log(event);
          setPosition(event.target.getLatLng());
        },
      }}
    >
      <Popup>
        Latitude: {position.lat.toFixed(4)}, Longitude:{" "}
        {position.lng.toFixed(4)}
      </Popup>
    </Marker>
  );
};

const CenterMarker = ({ position, setPosition }: any) => {
  const map = useMapEvent("moveend", () => {
    const newCenter = map.getCenter();
    setPosition(newCenter);
  });

  return <Marker position={position} />;
};

export const MapComponent = ({ onChange }: any) => {
  const [markerPosition, setMarkerPosition] = useState({lat: 25.0222899, lng: 67.0591304});

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setMarkerPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      });
    } else {
      console.log("Geolocation is not available in your browser.");
    }
  }, []);

  useEffect(() => {
    onChange && onChange(markerPosition);
  }, [markerPosition]);

  return (
    <MapContainer
      center={markerPosition}
      zoom={13}
      style={{ height: "50vh", width: "100%" }}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      />
      <CenterMarker position={markerPosition} setPosition={setMarkerPosition} />
    </MapContainer>
  );
};
